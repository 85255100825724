<template>
  <div class="contaner_o">
    <new-header :config="headerConfig"></new-header>
    <div class="content_box">
      <div class="content_top">
        <div class="select_box">
          <div>订单模块：</div>
          <van-dropdown-menu class="meun_box" @change="selectChange">
            <van-dropdown-item v-model="value1" :options="option1" />
          </van-dropdown-menu>
        </div>
        <div class="input_box">
          <van-icon @click="search" class="icon" size="20" name="search" />
          <input v-model="order" placeholder="请输入订单号" />
        </div>
      </div>
      <vue-loadmore
        style="margin-top: 30px"
        :on-refresh="onRefresh"
        :on-loadmore="onLoad"
        :finished="finished"
      >
        <div class="content_list">
          <van-radio-group v-model="radio">
            <div class="item_list" v-for="(item, index) in orderListData" :key="index">
              <div style="display: flex">
                <img class="image_box" :src="item.ordergoods.goods_image" />
                <div class="item_text">
                  <div class="title">{{ item.ordergoods.goods_name }}</div>
                  <div>订单编号：{{ item.order_sn }}</div>
                  <div>订单时间：{{ item.add_time }}</div>
                  <div>订单金额：{{ item.order_amount }}</div>
                </div>
              </div>
              <div class="radio_box">
                <div>已完成</div>
                <van-radio
                  style="margin-left: 10px"
                  :name="item.order_id"
                  label-disabled
                ></van-radio>
              </div>
            </div>
          </van-radio-group>
        </div>
      </vue-loadmore>

      <div class="clone_btn">
        <div @click="cloneBtn">取消</div>
        <div class="comfirm_btn" @click="comfirmBtn">确认</div>
      </div>
    </div>
    <van-empty
      v-if="orderListData.length == 0"
      class="custom-image"
      image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
      description="暂无数据"
    />
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import { serviceOrder_api } from "@/api/alteration";
// 数据
import * as apiCommon from "@/api/common";
export default {
  data() {
    return {
      bgcShow: true,
      bgcTitle: "订单选择",
      headerConfig: {
        show: true,
        title: "订单选择",
      },
      show: false,
      radio: "",
      order: "", //订单搜索
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      value1: 0,
      option1: [],
      query: {
        page: 1,
        limit: 10,
        order_search: "",
        // order_type:0
      },
      finished: false,
      orderListData: [],
      pages:0
    };
  },
  components: {
    newHeader,
  },
  created() {
    document.title = "";

    this.getSetting();
    if (localStorage.getItem("orderData")) {
      this.radio = JSON.parse(localStorage.getItem("orderData")).order_id;
    }
  },
  watch: {
    value1(val) {
      this.query = {
        page: 1,
        limit: 10,
        order_search: "",
        // order_type:0
      }
      switch (val) {
        case 0:
          delete this.query.order_type
          break;
        case 1:
          this.query.order_type = 20;
          break;
        case 2:
          this.query.order_type = 40;
          break;
        case 3:
          this.query.order_type = 50;
          break;
        case 4:
          this.query.order_type = 60;
          break;
        case 5:
          this.query.order_type = 70;
          break;
        case 6:
          this.query.order_type = 80;
          break;
        case 7:
          this.query.order_type = 130;
          break;
        case 8:
          this.query.order_type = 120;
          break;
        case 9:
          this.query.order_type = 90;
          break;
        case 10:
          this.query.order_type = 15;
          break;
        case 11:
          this.query.order_type = 6;
          break;
        case 12:
          this.query.order_type = 7;
          break;
        case 13:
          this.query.order_type = 1;
          this.query.is_rebate = 1;
          break;
        case 14:
          this.query.is_whole = 1;
          break;
        case 15:
          this.query.order_type = 1;
          this.query.repay_order = 1;
          break;
        default:
          this.query.order_type = 16;
          break;
      }
      this.query.page = 1;
      this.orderListData = [];
      this.getOrderList();
    },
  },
  methods: {
    async getSetting() {
      let data = {
        config: ["change_shares_config"],
      };
      let res = await apiCommon.configSetting(data);
      if (res.code == 0) {
        this.tableData = JSON.parse(res.data.change_shares_config);
        if (this.tableData.order_type[0] != 18) {
          this.option1 = [
            { text: "全部", value: 0 },
            { text: "批发电商", value: 1 },
            { text: "生活电商", value: 2 },
            { text: "扶贫电商", value: 3 },
            { text: "智教电商", value: 4 },
            { text: "旅游电商", value: 5 },
            { text: "艺术电商", value: 6 },
            { text: "氏客专区", value: 7 },
            { text: "创业免单", value: 8 },
            { text: "创业推广", value: 9 },
            // { text: "工会专区", value: 10 },
            { text: "团购", value: 11 },
            { text: "秒杀", value: 12 },
            { text: "创业全返", value: 13 },
            { text: "创业全品", value: 14 },
            { text: "创业分期", value: 15 },
            { text: "赊购专区", value: 16 },
            // { text: "福利专区", value: 17 },
          ];
        } else {
          this.option1 = [{ text: "福利专区", value: 0 }];
          this.query.order_type = 18;
        }
        this.getOrderList();
      }
    },
    selectChange(e) {
      console.log(e, 1);
    },
    submitBtn() {
      this.show = true;
    },
    getOrderList() {
      serviceOrder_api(this.query).then((res) => {
        if (res.code == 0) {
          this.orderListData = this.orderListData.concat(res.data);
          this.pages = res.pagination.pages
        }
      });
    },
    comfirmBtn() {
      console.log(this.radio);
      let selectIndex = -1;
      this.orderListData.forEach((e, index) => {
        if (e.order_id == this.radio) {
          selectIndex = index;
        }
      });
      if(selectIndex != -1){
        localStorage.setItem("orderData", JSON.stringify(this.orderListData[selectIndex]));
      }
      
      this.$router.go(-1);
    },

    search() {
      console.log(this.order);
      this.query.order_search = this.order;
      this.orderListData = [];
      this.finished = false;
      this.query.page = 1;
      this.getOrderList();
    },
    // 上拉加载------------------
    onRefresh(done) {
      this.orderListData = [];
      this.finished = false;
      this.query.page = 1;
      this.getOrderList();
      done();
    },
    onLoad(done) {
      if (this.query.page >= this.pages) {
        this.finished = true;
      } else {
        this.fetch();
      }
      done();
    },

    fetch() {
      this.query.page++;
      this.getOrderList();
    },
    cloneBtn(){
      this.$router.go(-1)
    },
    // end--------------------------------
  },
};
</script>
<style lang="less">
.contaner_o {
    background-color: #F9F9FB;
    min-height: 100vh;
    padding-bottom: 200px;

    .clone_btn {
        display: flex;
        position: fixed;
        bottom: 100px;
        left: 105px;
        width: 240px;
        height: 66px;
        background: #2F2B2B #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        text-align: center;
        line-height: 66px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: #1A1A1A;
        width: 100%;

        div {
            width: 240px;
            height: 66px;
            background: #FFFFFF;
            border-radius: 12px 12px 12px 12px;
        }

        .comfirm_btn {
            width: 240px;
            height: 66px;
            background: linear-gradient(180deg, #252324 0%, #3C3633 100%), #2F2B2B;
            border-radius: 12px 12px 12px 12px;
            margin-left: 60px;
            color: #fff
        }
    }

    .content_box {
        padding: 35px;

        .content_top {
            width: 680px;
            height: 220px;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
            border-radius: 20px 20px 20px 20px;
            padding: 35px 20px;
            box-sizing: border-box;
        }
    }

    .select_box {
        display: flex;
        line-height: 60px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: #1A1A1A;
    }

    .meun_box {
        width: 500px;
        height: 60px;
        background: #F6F6F6;
        border-radius: 10px 10px 10px 10px;
    }

    .van-dropdown-menu__bar {
        height: 60px !important;
        background-color: #F6F6F6;
    }

    .input_box {
        width: 640px;
        height: 60px;
        background: #F6F6F6;
        border-radius: 10px 10px 10px 10px;
        margin-top: 30px;
        display: flex;

        input {
            width: 500px;
            height: 50px;
            line-height: 50px;
            border: none;
            background: #F6F6F6;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
            padding-left: 20px
        }

        .icon {
            margin: 10px 0 0 20px;
        }
    }

    .content_list {
        .radio_box {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #0091FF;

            >div {
                margin-bottom: 34px;
            }
        }

        .item_list {
            width: 680px;
            height: 200px;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
            border-radius: 20px 20px 20px 20px;
            margin-top: 30px;
            padding: 21px 13px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            .image_box {
                width: 159px;
                height: 159px;
                border-radius: 10px 10px 10px 10px;
                background-color: blue;
            }

            .item_text {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 24px;
                color: rgba(26, 26, 26, 0.8);
                margin-left: 13px;

                div {
                    margin-bottom: 16px;
                }

                .title {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: bold;
                    font-size: 26px;
                    color: #1A1A1A;
                }
            }
        }
    }
}
</style>
